<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    emits:['barClick'],
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      eventFlag:{
        type:Boolean,
        default:false,
      },
      colors: {
        type: Array,
        default: ()=>{
          return ['#3ABB83','#F6F853','#F6BF5E', '#F65559']
        }
      },
      series: {
        type: Array,
        default: ()=>{
          return  [
              {
                data: [12, 20],
                type: 'bar',
                barWidth:30,
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
              }
          ]
        }
      },
      xAxis:{
        type: Array,
        default: ()=>{
          return ['温度','湿度']
        }
      },
      yAxis:{
        type:Object,
        default:{
          type: 'value',
          name:'百分比（%）',
          min:0,
          max:100
        }
      }
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'series':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
          this.chart.off('click')
          this.chart.on('click',  (params)=> {
            if(this.eventFlag){
              this.$emit('barClick',params)
            }
          })
         
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            xAxis: {
              type: 'category',
              data: this.xAxis
            },
            color:this.colors,
            yAxis: this.yAxis,
            series:this.series
          }) 
          
          
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>