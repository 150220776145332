<template>
    <el-dialog
    v-model="dialogVisible"
    :title="isAdd?'新增':'编辑'"
    width="500px"
    >
    <el-form
    ref="form"
    :model="ruleForm"
    label-width="100px"
    >
        <el-form-item 
        label="环境名称" 
        prop="envname"
        :rules="{ required: true, message: '环境名称不能为空', trigger: 'blur' }">
            <el-input v-model="ruleForm.envname" />
        </el-form-item>
        <el-form-item 
        label="环境类型" 
        prop="envtype"
        :rules="{ required: true, message: '环境类型不能为空', trigger: 'blur' }">
            <el-select v-model="ruleForm.envtype" placeholder="请选择材质">
                <el-option v-for="(item, index) in envtypeS" :label="item.name" :value="item.id" />
            </el-select>  
        </el-form-item>
        
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFun('form')">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { site_listAdd,  site_listEdit } from '@/api/site-warning.js'
export default{
    emits:['OK'],
    data(){
        return{
            isAdd:true,
            dialogVisible:false,
            ruleForm:{
                siteid:null,
                fid:null,
                envname:null,
                envtype:'1',
                id:null
            },
            envtypeS:[
                {name:'展厅',id:'2'},
                {name:'展柜',id:'1'}
            ]
        }
    },
    methods:{
        open(type, data){
            this.isAdd = type
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.form.resetFields()
                if(type){
                  this.ruleForm.siteid = data.siteid
                  this.ruleForm.fid = data.id
                  this.ruleForm.id = null
                }else{
                  this.ruleForm.siteid = data.siteid
                  this.ruleForm.fid = data.fid 
                  this.ruleForm.envname = data.envname
                  this.ruleForm.envtype = data.envtype
                  this.ruleForm.id = data.id
                }
            })
        },
        submitFun(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.isAdd){
                        site_listAdd(this.ruleForm).then(r=>{
                            this.$emit('OK',r.data)
                        })
                    }else{
                        site_listEdit(this.ruleForm).then(r=>{
                            this.$emit('OK',this.ruleForm)
                        })
                    }
                    this.dialogVisible = false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>