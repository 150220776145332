<template>
    <div class="pageBox">
        <div class="topBox">
            <div class="topItem">
                <img src="../../assets/siteImg/D.png">
                <div class="topMsg">
                    <div>当日报警数量</div>
                    <div class="topNumber">
                        <div><span class="spanNum">{{ day  }}</span>条</div>
                        <div class="tips">较昨日 
                            <span :class="dayTrend<0?'statuRed':'statuGreen'">{{dayTrend<0?'↓':'↑'}}</span>
                            {{ Math.abs(dayTrend) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="topItem">
                <img src="../../assets/siteImg/W.png">
                <div class="topMsg">
                    <div>本周报警数量</div>
                    <div class="topNumber">
                        <div><span class="spanNum">{{ week }}</span>条</div>
                        <div class="tips">较上周 
                            <span :class="weekTrend<0?'statuRed':'statuGreen'">{{weekTrend<0?'↓':'↑'}}</span>
                            {{ Math.abs(weekTrend) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="topItem">
                <img src="../../assets/siteImg/M.png">
                <div class="topMsg">
                    <div>本月报警数量</div>
                    <div class="topNumber">
                        <div><span class="spanNum">{{ month }}</span>条</div>
                        <div class="tips">较上月 
                            <span :class="monthTrend<0?'statuRed':'statuGreen'">{{monthTrend<0?'↓':'↑'}}</span>
                            {{ Math.abs(monthTrend) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="topItem">
                <img src="../../assets/siteImg/D.png">
                <div class="topMsg">
                    <div>待处理报警数量</div>
                    <div class="topNumber">
                        <div><span class="spanNum">{{ num }}</span>条</div>
                        <div class="tips"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomBox">
            <div class="titleBox titleCss">
                <div>
                    <img src="../../assets/siteImg/title.png">
                    环境指标风险超限预警概览
                </div>
                <div class="operBar">
                    <el-date-picker
                    v-model="TimeRang1"
                    type="datetimerange"
                    range-separator="-"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    />
                    <el-select class="marginCss" @change="buildChange" v-model="build" placeholder="栋">
                        <el-option
                        v-for="item in builds"
                        :key="item.envId"
                        :label="item.envName"
                        :value="item.envId"
                        />
                    </el-select>
                    <el-select class="marginCss" @change="floorChange" v-model="floor" placeholder="层">
                        <el-option
                        v-for="item in floors"
                        :key="item.envId"
                        :label="item.envName"
                        :value="item.envId"
                        />
                    </el-select>
                    <el-select class="marginCss" @change="roomChange" v-model="room" placeholder="小环境">
                        <el-option
                        v-for="item in rooms"
                        :key="item.envId"
                        :label="item.envName"
                        :value="item.envId"
                        />
                    </el-select>
                    <el-select class="marginCss" v-model="posi" placeholder="微环境">
                        <el-option
                        v-for="item in posis"
                        :key="item.envId"
                        :label="item.envName"
                        :value="item.envId"
                        />
                    </el-select>

                    <el-button class="marginCss" type="primary" @click="Get_site_queryScore">查询</el-button>
                </div>
            </div>
            <div class="titleMsgBox titleCss">
                <div class="BB_item">
                    <lineChart :series="scoreline" :yAxis="scoreyAxis" />
                </div>
                <div class="BB_item">
                    <lineChart :series="WSDline" :yAxis="WSDyAxis" />
                </div>
               
            </div>
        </div>
        <div class="centerBox">
            <div class="titleBox titleCss">
                <div>
                    <img src="../../assets/siteImg/title.png">
                    离线评估
                </div>
                <div>
                </div>
            </div>
            <div class="titleMsgBox chartBox">
               <div class="leftC">
                <div class="leftBtn">
                    <el-button size="small" @click="addFun(null)" type="primary"><svg-icon icon-class="plus"/>新增</el-button>
                </div>
                <el-tree 
                ref="tree"
                :data="PGbuild"  
                node-key="id" 
                :current-node-key="treeid"
                highlight-current
                default-expand-all
                :expand-on-click-node="false"
                @node-click="handleNodeClick"
                :props="defaultProps">
                <template #default="{ node, data }">
                    <span class="custom-tree-node">
                        <span>{{ node.label }}</span>
                        <span>
                            <el-button type="primary" link @click="addFun(data)"> <svg-icon icon-class="plus" /> </el-button>
                            <el-button type="primary" link @click="editFun(data)"> <svg-icon icon-class="edit" /> </el-button>
                            <el-button type="primary" link @click="delFun(node,data)"> <svg-icon icon-class="del" /> </el-button>
                        </span>
                    </span>
                </template>
                </el-tree>
               </div>
               <div class="rightC">
                <div>
                    <el-input style="width: 300px;margin-right: 10px;" v-model="query.relicname" placeholder="请输入文物名称"/>
                    <el-button type="primary" :disabled="this.query.envid?false:true" @click="CX"> 查询 </el-button>
                    <el-button type="primary" :disabled="this.query.envid?false:true" @click="this.$refs.addRelic.open(this.query.envid)"> 
                        <svg-icon icon-class="plus"/>
                        新增 
                    </el-button>
                </div>
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="relicname" label="文物名称" />
                    <el-table-column prop="material" label="材质" />
                    <el-table-column prop="detail" label="详情" />
                    <el-table-column prop="disease" label="病害信息" />
                    <el-table-column label="操作" width="300">
                        <template #default="scope">
                            <el-button @click="lookMsg(scope.row.id)">查看记录</el-button>
                            <el-button @click="risk(scope.row.id)">环境风险评估</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-pagination 
                v-model:current-page="query.page"
                :page-size="query.size"
                @current-change="pageChange"
                layout="prev, pager, next" 
                :total="total" /> -->
               </div>
            </div>
        </div>
        <operForm ref="operForm"/>
        <addRelic ref="addRelic" @OK="CX"/>
        <operTree ref="operTree" @OK="operTreeFun" />
    </div>
</template>
<script>
import barChart from '@/components/charts/barChart.vue'
import lineChart from '@/components/charts/lineChart.vue'
import { site_listExceedRatio, site_list, site_listDel, site_relics, site_queryAlarmCount} from '@/api/site-warning.js'
import { site_queryScore, site_envList } from '@/api/site-home.js'
import { mapGetters } from 'vuex'
import operForm from './modules/operForm.vue'
import addRelic from './modules/operRelic.vue'
import operTree from './modules/operTree.vue'
export default{
    components:{
        barChart,
        lineChart,
        operForm,
        addRelic,
        operTree
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            day:0,
            dayTrend:0,
            monthTrend:0,
            weekTrend:0,
            week:0,
            month:0,
            num:0,
            treeid:1,
            TimeRang1:['2023-10-08','2023-10-17'],
            exhibitionids:[],
            builds:[],
            build:null,
            floors:[],
            floor:null,
            rooms:[],
            room:null,
            posis:[],
            posi:null,
            WSDline:[],
            WSDyAxis:[
                {
                    name: '百分比（%）',
                    type: 'value',
                    min:0,
                    max:100
                },
            ],
            scoreline:[{
                name: '风险评分',
                type: 'line',
                smooth: true,
                symbol: 'none',
                data: [],
                markArea: {
                    "data": [
                        [{
                            "yAxis": "0",
                            "itemStyle": {
                                "color": "#F6555963"
                            }
                        }, {
                            "yAxis": "25"
                        }],
                        [{
                            "yAxis": "25",
                            "itemStyle": {
                                "color": "#F6BF5E63"
                            }
                        }, {
                            "yAxis": "50"
                        }],
                        [{
                            "yAxis": "50",
                            "itemStyle": {
                                "color": "#F6F85363"
                            }
                        }, {
                            "yAxis": "75"
                        }],
                        [{
                            "yAxis": "75",
                            "itemStyle": {
                                "color": "#3ABB8363"
                            }
                        }, {
                            "yAxis": "100"
                        }],
                    ]
                }
            }],
            scoreyAxis:[
                {
                    name: '风险评分',
                    type: 'value',
                    min:0,
                    max:100,
                    splitNumber:4,
                    interval:25,
                },
            ],
            currentNode:null,
            PGbuild:[],
            defaultProps:{
                children: 'children',
                label: 'envname',
            },
            tableData:[],
            query:{
                envid:null,
                relicname:null,
            },
            total:0,
        }
    },
    mounted(){
 
        this.Get_option(0, this.siteId)
        this.getBase()
    },
    methods:{
        getBase(){
            let query = {
                code:this.siteId,
                siteid:this.siteId
            }
            site_list(query).then(r=>{
                this.PGbuild = r.data 
                if(this.PGbuild.length == 0){
                    return
                }
                this.$nextTick(()=>{
                    this.treeid = r.data[0].id
                    this.$refs.tree.setCurrentKey(r.data[0].id)
                    this.query.envid = r.data[0].id
                    this.CX()
                })
                
            })
            site_queryAlarmCount({'siteid':this.siteId}).then(r=>{
                this.day = r.data.day
                this.week = r.data.week
                this.month = r.data.month
                this.num = r.data.status[0].num
                this.dayTrend = r.data['day-trend']
                this.monthTrend = r.data['month-trend']
                this.weekTrend = r.data['week-trend']
            })
        },
        Get_site_queryScore(){
            if(!this.TimeRang1||!this.posi){
                this.$message.warning('请补充查询条件')
                return
            }
            let Query = {
                envid:this.posi,
                start:this.TimeRang1[0],
                end:this.TimeRang1[1]
            }
            site_queryScore(Query).then(r=>{
                this.scoreline[0].data = r.data.map(i=>{
                    return [i.day, i.score]
                })
            })
            site_listExceedRatio(Query).then(r=>{
                this.WSDline = [
                    {
                        name: '温度超限时长',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {},
                        data: r.data['02'].map(i=>{
                            return [i.daytime, (i.radio*100).toFixed(2)]
                        })
                    },
                    {
                        name: '湿度超限时长',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {},
                        data: r.data['01'].map(i=>{
                            return [i.daytime, (i.radio*100).toFixed(2)]
                        })
                    }
                ]
            })
        },
        Get_option(type, envId){
            let minQuery2 = {
                'siteId':this.siteId,
                'envId':envId,
                'isSelf':false,
                'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                if(type == 0 ){
                    this.builds = res.data
                    this.initData(res.data[0].envId)
                }else if(type == 1){
                    this.floors = res.data
                }else if(type == 2){
                    this.rooms = res.data
                }else{
                    this.posis = res.data
                }
            })
        },
        initData(data){
            this.build = data
            let minQuery2 = {
                'siteId':this.siteId,
                'envId':data,
                'isSelf':false,
                'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.floors = res.data
                this.floor = res.data[0].envId
                minQuery2.envId = this.floor 
                site_envList(minQuery2).then(res=>{
                    this.rooms = res.data
                    this.room = res.data[0].envId
                    minQuery2.envId = this.room 
                    site_envList(minQuery2).then(res=>{
                        this.posis = res.data
                        this.posi = res.data[0].envId
                        this.Get_site_queryScore()
                    })
                })
            })
        },
        buildChange(val){
            this.build = val
            this.floor = null
            this.room = null
            this.posi = null
            this.Get_option(1,val)
        },
        floorChange(val){
            this.floor = val
            this.room = null
            this.posi = null
            this.Get_option(2,val)
        },
        roomChange(val){
            this.room = val
            this.posi = null
            this.Get_option(3,val)
        },
        pageChange(page){
            this.query.page = page
        },
        makeData(){
            let base = +new Date(2023, 7, 1);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 50]];
            for (let i = 1; i < 100; i++) {
                let now = new Date((base += oneDay));
                data.push([+now, Math.round((Math.random() - 0) * 50 )]);
            }
            return data
        }, 
        risk(id){
            this.$refs.operForm.open(id)
        },
        lookMsg(id){
            let routeUrl = this.$router.resolve({
                'name':'whistory'
            })
            window.open(`${routeUrl.href}?relic=${id}`, '_blank')
        },
        handleNodeClick(e){
            this.query.envid = e.id
            this.CX()
        },
        CX(){
            site_relics(this.query).then(r=>{
                this.tableData = r.data
            })
        },
        addFun(data){
            this.currentNode = data
            if(data){
                this.$refs.operTree.open(true,data)
            }else{
                this.$refs.operTree.open(true,{siteid:this.siteId,id:0})
            }
        },
        editFun(data){
            this.currentNode = data
            this.$refs.operTree.open(false,data)
        },
        delFun(node,data){
            this.currentNode = data
            site_listDel(data.id).then(r=>{
                const parent = node.parent
                const children = parent.data.children || parent.data
                const index = children.findIndex((d) => d.id === data.id)
                children.splice(index, 1)
            }) 
        },
        operTreeFun(data){
            if(this.currentNode){
                if(this.currentNode.id == data.id){
                    this.currentNode.envname = data.envname
                    this.currentNode.envtype = data.envtype
                }else{
                    if(this.currentNode.children){
                    }else{
                        this.currentNode['children'] = []
                    }
                    this.currentNode.children.push(data) 
                }
            }else{
                this.getBase() 
            }   
        },


    }
}
</script>
<style lang="scss" scoped>
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}
.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    .topBox{
        width:100%;
        height:150px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        .topItem{
            width:calc(25% - 20px);
            height:100%;
            background-color:#fff;
            border:1px solid rgb(213, 213, 213);
            border-radius:10px;
            box-sizing:border-box;
            display:flex;
            justify-content:space-around;
            align-items:center;
            img{
                width:90px;
                height:90px;
                object-fit:contain;
            }
            .topMsg{
                width:calc(100% - 180px);
                height:90px;
                display:flex;
                flex-direction:column;
                justify-content:space-between; 
                font-size:20px;
                .topNumber{
                    display:flex;
                    justify-content:space-between;
                    align-items: baseline;
                    .spanNum{
                        font-size:40px;
                    }
                    .tips{
                        font-size:16px;
                    }
                    .statuGreen{
                        color:#22bd22;
                        font-weight:bolder;
                        font-size:20px;
                    }
                    .statuRed{
                        color:#c62626;
                        font-weight:bolder;
                        font-size:20px;
                    }
                }
            }
        }
    }
    .centerBox{
        width:100%;
        height:500px;
        margin-top:20px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        .chartBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            .leftC{
                width: 400px;
                height: 100%;
                overflow: auto;
                padding: 0px 20px;
                box-sizing: border-box;
                .leftBtn{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    height: 30px;
                }
            }
            .rightC{
                width: calc(100% - 400px);
                height: 100%;
                border-left: 1px solid #999;
                padding: 0px 20px;
                box-sizing: border-box;
            }
        }
    }
    .bottomBox{
        width:100%;
        height:500px;
        margin-top:20px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        .operBar{
            display:flex;
            justify-content:space-between;
            align-items:center;
            .marginCss{
                margin-left:20px;
            }
        }
        .BB_item{
            width:calc(50% - 10px);
            height:100%;
        }
    }
}
/deep/.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.custom-tree-node{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>